<template>
  <select-server
    :id="id"
    v-model="value"
    :name="'EstadoSelect'"
    :label="'sigla'"
    :invalid="invalid"
    :title="title"
    :request="request"
    @input="$emit('input', value)"
  />
</template>

<script>
import api from './api'

export default {
  name: 'EstadoSelect',
  components: {
    SelectServer: () => import('@pilar/plugins/select-server/SelectServer.vue'),
  },
  props: {
    value: {
      type: Object,
      default: () => { },
    },
    id: {
      type: [String, Number],
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      api,
    }
  },
  methods: {
    request() {
      return new Promise((resolve, reject) => {
        api.getEstados()
          .then(payload => {
            resolve({
              data: payload.data.map(map => ({
                ibge: map.id,
                sigla: map.sigla,
              })),
            })
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>
